import('~/lib/setup_globals');
import('popper.js');
import('@hotwired/turbo-rails');
import('@hotwired/stimulus');
import('leaflet');
import('datatables.net');
import('spectrum-colorpicker/spectrum');
import('@rails/request.js');
import('~/textFilter');
import('~/initDatatables');
import('~/fileUpload');
import('~/inspectorBehavior');
import('~/spaceDataFilter');
import('~/floorplan');
import('~/controllers');
import('~/lib/jquery_global_setup');
